import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    // FIXME: Remove modal related state as it is unused
    open: false,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    theme: {},
    formState: { data: {} },
    setFormState: fieldData => {
      this.setState(state => ({
        formState: { ...state.formState, ...fieldData },
      }));
    },
    formStep: 0,
    setFormStep: () =>
      this.setState(state => ({
        formStep: state.formStep + 1,
      })),
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
