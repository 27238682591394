/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

/*
 * Add Google Font typefaces
 * See: https://github.com/gatsbyjs/gatsby-starter-blog/blob/master/gatsby-browser.js
 */
import 'typeface-oswald';
import 'typeface-montserrat';
import 'typeface-nunito-sans';
import 'typeface-roboto';

/*
 * Fix for font flickering on React component updates
 * See: https://github.com/gatsbyjs/gatsby/issues/9826#issuecomment-442303407
 */
import './src/global.css';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

/*
 * Fire Google Tag Manager event that acts as a page view trigger.
 * See: https://www.analyticsmania.com/post/ultimate-google-tag-manager-data-layer-tutorial/#event-as-trigger
 * and: https://stackoverflow.com/a/47939590
 * and: https://stackoverflow.com/a/49322315
 */
export const onRouteUpdate = ({ location }) => {
  setTimeout(() => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'GatsbyRouteUpdate',
      page: {
        path: location.pathname,
        title: document.title,
      },
    });
  }, 0);
};
